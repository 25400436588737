import React from 'react';
import PropTypes from 'prop-types';

import { PhotoSeriesQualityRating } from '../../../../../../components';
import { constants as imagesConstants, useImageTypeErrors } from '../../../../../../redux/images';

const PhotoSeriesQualityRatingSection = ({ isLoadingPhotoSeriesDetails, photoSeries }) => {
  const imageTypeErrors = useImageTypeErrors();

  const photoSeriesImageErrors = photoSeries
    && photoSeries.errors
    && photoSeries.errors.map((errorCode) => imagesConstants.IMAGE_VERIFICATION_ERROR_DISPLAY_TEXT[errorCode.error]);

  const PhotoSeriesQualityRatingProps = {
    isLoading: isLoadingPhotoSeriesDetails || !photoSeries,
    qualitiyRating: photoSeries && photoSeries.qualityRating,
    photoSeriesImageErrors,
    imageTypeErrors
  };

  return <PhotoSeriesQualityRating {...PhotoSeriesQualityRatingProps} />;
};

PhotoSeriesQualityRatingSection.propTypes = {
  isLoadingPhotoSeriesDetails: PropTypes.bool.isRequired,
  photoSeries: PropTypes.object
};

export default PhotoSeriesQualityRatingSection;
